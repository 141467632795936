import * as React from "react";
import type { HeadFC } from "gatsby";
import Footer from "../components/Footer";
import cv from "../images/2024.pdf";
import classNames from "classnames";

const data = {
  name: "Thanh Tschoepe",
  description:
    "<strong>Senior Software Engineer</strong> known for exceptional project leadership and strategic problem-solving. Track record of breaking down complex technical challenges while fostering cross-team collaboration. Consistently praised for strong technical depth and ability to quickly deliver impact in high-scale environments.",
  goal: "Seeking a <strong>Technical Lead</strong> or <strong>Staff Engineer</strong> role to drive technical excellence and cross-functional collaboration while architecting scalable solutions that impact millions of users.",
  contact: [
    {
      link: "nguyendaithanh0612@gmail.com",
      content: "nguyendaithanh0612@gmail.com",
      icon: "✉️",
      type: "link",
      kind: "mailto",
    },
    {
      link: "https://www.linkedin.com/in/thanhng-97/",
      content: "linkedin.com/in/thanhng-97/",
      icon: "🔗",
      type: "link",
    },
    {
      link: "https://meowbark.dev",
      content: "meowbark.dev",
      icon: "🌐",
      type: "link",
    },
  ],
  education:
    "Bachelor Degree in Computer Science, Metropolia University, Helsinki Finland",
  experience: [
    {
      title: "Senior Software Engineer II",
      company: "HubSpot",
      location: "Austin, TX",
      duration: ["September 2023", "Present"],
      contents: [
        "Led architecture and delivery of Nested Association Table and URL-based view state, recognized as 'the most impactful work' in CRM's highest-traffic application serving 15M+ weekly users",
        "Established cross-team RFC culture and spearheaded critical infrastructure improvements, preventing Q1 2023 code freeze while enabling continuous development",
        "Achieved 30% faster page loads through webpack optimization and preload scripts in HubSpot's highest-traffic application",
        "Drove engineering excellence through knowledge transfer sessions, technical pattern development, and collaboration with Design and Product teams",
      ],
      skills: [
        "webpack",
        "performance",
        "technical leadership",
        "typescript",
        "react",
        "mentorship",
        "infrastructure design",
        "RFC process",
        "documentation",
      ],
    },
    {
      title: "Senior Software Engineer",
      company: "Project44",
      location: "Austin",
      duration: ["January 2023", "June 2023"],
      contents: [
        "Led the development of a key UPS integration, enabling real-time shipment data access for 50+ brands and saving millions in data costs.",
        "Revitalized a paused project enabling 120+ brands to customize their end-consumer tracking experience.",
      ],
      skills: [
        "typescript",
        "react",
        "express",
        "nextjs",
        "postgres",
        "elasticsearch",
      ],
    },
    {
      title: "Senior Software Engineer, Lead Engineer",
      company: "Everlance",
      location: "Austin, TX",
      duration: ["March 2022", "January 2023"],
      contents: [
        "Implemented an enterprise expense reporting and payroll pipeline product, leading refactoring of key features.",
        "Led migration to Typescript and React 18, reducing bug ticket volume by 43% while mentoring team members.",
        "Authored a company-wide component library, accelerating development speed.",
      ],
      skills: ["typescript", "react", "tailwind", "S3", "docker", "team lead"],
    },
    {
      title: "Software Engineer, Team Lead",
      company: "Zalando",
      location: "Helsinki, Finland",
      duration: ["January 2019", "April 2021"],
      contents: [
        "Led the retailer product team (3 members), managing client-facing microservices and API gateway.",
        "Collaborated with Product to revamp data product, reducing query to sub 200ms p95.",
        "Established SRE infrastructure enabling 2k orders/min load testing and founded OAuth2 authentication system serving 30k accounts.",
      ],
      skills: [
        "typescript",
        "python",
        "react",
        "postgres",
        "kubernetes",
        "team lead",
      ],
    },
    {
      company: "Gofore, Medisapiens",
      location: "Helsinki, Finland",
      duration: ["March 2017", "April 2021"],
      contents: [],
      skills: [],
    },
  ],
  skills: {
    "programming languages": [
      "typescript",
      "javascript",
      "java",
      "python",
      "rust",
    ],
    backend: ["nodejs", "graphql", "nextjs", "axum"],
    frontend: ["react", "vue", "svelte", "tailwind", "htmx", "webpack"],
    "data & infrastructure": [
      "postgres",
      "redis",
      "elasticsearch",
      "dynamodb",
      "AWS",
      "kubernetes",
      "docker",
      "microservices",
    ],
    "engineering excellence": [
      "team lead",
      "system design",
      "performance",
      "mentorship",
      "documentation",
    ],
  },
  references: [
    {
      name: "Gustavo Reyes",
      title: "Senior Engineering Manager",
      company: "Project44",
      link: "https://www.linkedin.com/in/gustavo-c-reyes/",
    },
    {
      name: "Giovanni Ferrara",
      title: "Team Lead",
      company: "Zalando",
      link: "https://www.linkedin.com/in/giovanniferrara/",
    },
    {
      name: "Surbhi Marwah",
      title: "Head of Product",
      company: "Zalando",
      link: "https://www.linkedin.com/in/superbae/",
    },
    {
      name: "Christian Tschoepe",
      title: "Staff Engineer",
      company: "Zendesk",
      link: "https://www.linkedin.com/in/chrants/",
    },
  ],
  projects: [
    {
      name: "Data visualization",
      description: "Graph, charts and visualization using d3",
      link: "https://tinyurl.com/59ftw2hw",
    },
    {
      name: "Organization charts POC",
      description: "A proof of concept for a team tree view for Everlance",
      link: "https://tinyurl.com/4x8xzvnh",
    },
    {
      name: "Mileage Tracker",
      description: "Real-time tracking app built with React and InfluxDB.",
      link: "https://meoww.vercel.app/",
    },
    {
      name: "Svelte & Tailwind Component Library",
      description:
        "Accessible UI component library using Svelte and Tailwind CSS.",
    },
  ],
};

const IndexPage = () => {
  const printFn = typeof window !== "undefined" ? window.print : () => {};
  // Order of buckets
  const order = [
    "programming languages",
    "frontend",
    "backend",
    "data & infrastructure",
    "engineering excellence",
  ];

  // Define color for each bucket
  const colors: Record<string, string> = {
    "programming languages": "bg-green-200",
    frontend: "bg-blue-200",
    backend: "bg-red-200",
    "data & infrastructure": "bg-yellow-200",
    "engineering excellence": "bg-indigo-200",
  };

  const orderedSkills = order.flatMap((bucket) =>
    //@ts-ignore
    data.skills[bucket].map((skill) => ({ skill, color: colors[bucket] })),
  );

  const sortFn = (a: string, b: string) => {
    const skillAIndex = orderedSkills.findIndex((s) => s.skill === a) ?? 10;
    const skillBIndex = orderedSkills.findIndex((s) => s.skill === b) ?? 10;
    return skillAIndex - skillBIndex;
  };

  return (
    <main>
      <div className="p-4 mx-auto pb-14 md:container md:py-4 lg:p-32 print:max-w-full print:p-6">
        <div className="flex flex-col gap-3">
          <section aria-roledescription="Main description">
            <div className="relative -top-2 print:hidden group flex gap-0.5">
              <button
                onClick={() => printFn()}
                className="p-1 px-2 m-0 font-medium text-white transition-all duration-200 bg-gray-500 shadow-none rounded-xl md:mr-2 md:mb-2 hover:shadow-lg hover:shadow-violet-500/50"
              >
                🖨️
              </button>
              <a
                href={cv}
                download
                className="p-1.5 px-2 m-0 text-sm text-white transition-all duration-200 rounded-xl shadow-none md:mr-2 md:mb-2 hover:shadow-lg bg-gray-700"
              >
                Get a PDF Copy
              </a>
              <span className="absolute hidden text-sm text-green-500 lg:group-hover:block -top-14">
                You can save this as pdf
              </span>
            </div>
            <div className="block md:flex md:items-baseline md:justify-between md:gap-8 whitespace-nowrap print:flex print:items-baseline print:justify-between print:gap-8">
              <h1 className="font-semibold md:text-5xl print:text-3xl">
                {data.name}
              </h1>
              <div className="flex flex-wrap">
                {data.contact.map((channel) => {
                  return (
                    <div
                      key={channel.content}
                      className="px-2 text-gray-500 print:text-sm"
                    >
                      {channel.link ? (
                        <a href={channel.link} className="underline link">
                          {channel.content}
                        </a>
                      ) : (
                        <span>{channel.content}</span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="py-2">
              <p
                role="description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></p>
              <p
                className="mt-1"
                dangerouslySetInnerHTML={{
                  __html: data.goal,
                }}
              ></p>
            </div>
          </section>

          <section aria-roledescription="skillset">
            <div className="flex items-center gap-4">
              <h1 className="font-semibold">Skills</h1>
              <hr className="grow" />
            </div>
            <div className="grid grid-cols-2 gap-0 md:grid-cols-5 print:grid-cols-5">
              {Object.entries(data.skills).map(([cat, skills]) => (
                <div className="col-1">
                  <p className="mb-0.5 text-sm capitalize text-gray-600">
                    {cat}
                  </p>
                  <div className="flex flex-wrap gap-0.5">
                    {skills.map((skill) => (
                      <span
                        className={classNames(
                          "text-sm tag",
                          colors[cat].toString(),
                        )}
                        key={skill}
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section aria-roledescription="Working experience">
            <div className="flex items-center gap-4">
              <h1 className="font-semibold">Professional Experience</h1>
              <hr className="grow" />
            </div>

            {data.experience.map((exp) => {
              exp.skills.sort(sortFn);
              return (
                <div key={exp.company} className="my-1">
                  <div>
                    <div className="flex flex-col md:flex-row md:items-baseline md:justify-between print:flex-row print:items-baseline print:justify-between">
                      <h2 className="font-semibold text-gray-800 text-md">
                        {exp.company}
                      </h2>
                      <p className="text-sm font-medium">
                        {exp.duration.join(" - ")}
                      </p>
                    </div>
                    {exp.title && (
                      <p className="-mt-1 font-medium text-gray-500">
                        {exp.title}
                      </p>
                    )}
                  </div>

                  <ul className="list-disc">
                    {exp.contents.map((cont) => (
                      <li
                        className={
                          exp.contents.length === 1 ? "list-none" : "ml-4 "
                        }
                        key={cont}
                      >
                        {cont}
                      </li>
                    ))}
                  </ul>
                  <div className="flex flex-wrap gap-0.5">
                    {exp.skills.map((skill, i) => {
                      const { color } =
                        orderedSkills.find((s) => s.skill === skill) || {};
                      return (
                        <span
                          className={classNames("text-sm tag", color)}
                          key={i}
                        >
                          {skill}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </section>

          <section aria-roledescription="education">
            <div className="flex items-center gap-2">
              <h1 className="font-semibold">Education</h1>
              <hr className="grow" />
            </div>
            <p>{data.education}</p>
          </section>

          <section aria-roledescription="project" className="print:hidden">
            <div className="flex items-center gap-2">
              <h1 className="font-semibold">Selected Projects</h1>
              <hr className="grow" />
            </div>

            {data.projects && (
              <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                {data.projects.map((project) => {
                  return (
                    <div key={project.name} className="mb-2">
                      <h4 className="font-medium">{project.name}</h4>
                      <p>{project.description}</p>
                      {project?.link && (
                        <a className="underline link" href={project.link}>
                          {project?.link}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Thanh Tschoepe's 2024 Resume</title>;
